type Tlanguages = 'en' | 'de';
type IStringTranslations = {
  [key in Tlanguages]: string;
};

interface IObjectTranslations {
  navbar: {
    users_list: IStringTranslations,
    edit_profile: IStringTranslations,
    logout: IStringTranslations
  },
  event: {
    countdown_title: IStringTranslations,
    program: IStringTranslations
  },
  save_the_date: {
    header_title: IStringTranslations,
    header_subtitle: IStringTranslations,
  },
  video: {
    program: IStringTranslations
  },
  chat: {
    header_title: IStringTranslations,
    header_subtitle: IStringTranslations,
    textbox_placeholder: IStringTranslations,
    check_anonym_message: IStringTranslations,
    check_top_rated: IStringTranslations,
    check_most_recent: IStringTranslations,
  },
  agenda: {
    header_title: IStringTranslations,
    header_subtitle: IStringTranslations,
  },
  polls: {
    header_title: IStringTranslations,
    header_subtitle: IStringTranslations,
  },
  survey: {
    survey_title: IStringTranslations,
  },
}

export const translations: IObjectTranslations = {
  navbar: {
    users_list: {
      'en': 'Users list',
      'de': 'Users list'
    },
    edit_profile: {
      'en': 'Edit profile',
      'de': 'Edit profile'
    },
    logout: {
      'en': 'Logout',
      'de': 'Logout'
    },
  },
  event: {
    countdown_title: {
      'en': 'THE EVENT WILL START IN:',
      'de': 'THE EVENT WILL START IN:'
    },
    program: {
      'en': 'Program of the event',
      'de': 'Programm'
    },
  },
  save_the_date: {
    header_title: {
      'en': 'SAVE THE DATE ON YOUR CALENDAR',
      'de': 'Sichern Sie sich jetzt schon den Termin in Ihrem Kalender!'
    },
    header_subtitle: {
      'en': 'Please select your calendar tool',
      'de': 'Please select your calendar tool'
    },
  },
  video: {
    program: {
      'en': 'View Program',
      'de': 'Programm'
    }
  },
  chat: {
    header_title: {
      'en': 'LIVE QUESTION',
      'de': 'LIVE QUESTION'
    },
    header_subtitle: {
      'en': '',
      'de': '',
    },
    textbox_placeholder: {
      'en': 'Type here your questions to interact live... (max 500 characters - English only)',
      'de': 'Stellen Sie hier Ihre Fragen live... (max. 500 zeichen)'
    },
    check_anonym_message: {
      'en': 'Send anonymous message',
      'de': 'Frage anonymous stellen'
    },
    check_top_rated: {
      'en': 'Top rated first',
      'de': 'Am höchsten bewertet zuerst'
    },
    check_most_recent: {
      'en': 'Most recent first',
      'de': 'Neuest zuerst'
    },
  },
  agenda: {
    header_title: {
      'en': 'LIVE AGENDA',
      'de': 'LIVE AGENDA'
    },
    header_subtitle: {
      'en': 'Please select the date:',
      'de': 'Bitte wählen Sie das Datum aus:'
    },
  },
  polls: {
    header_title: {
      'en': 'LIVE POLLS',
      'de': 'LIVE ABSTIMMUNG'
    },
    header_subtitle: {
      'en': '',
      'de': ''
    },
  },
  survey: {
    survey_title: {
      'en': 'FEEDBACK SURVEY',
      'de': 'FEEDBACK SURVEY'
    }
  },
}