import React, { useEffect } from 'react';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import { showLoader, hideLoader } from '../../config/functions';

import LivePageVideo from '../../Components/LivePage/LivePageVideo';
import LivePageAgenda from '../../Components/LivePage/LivePageAgenda';
import LivePageDocuments from '../../Components/LivePage/LivePageDocuments';
import LivePagePolls from '../../Components/LivePage/LivePagePolls';
import LivePageQuestionChat from '../../Components/LivePage/LivePageQuestionChat';

interface IProps {
    userData: MembersTypeRes.IMember | null,
    groupData: MembersTypeRes.IGroupAssociation | null,
}

var childComponentsLoaded = 0;
var childComponentsToLoad = 5;

const LivePageTemplateTwo = ({ userData, groupData }: IProps) => {

    useEffect(() => {
        showLoader();
    }, []);

    // When all child components are loaded show the page:
    // - VIDEO
    // - CHAT
    // - AGENDA
    // - POLLS
    // - DOCS
    window.addEventListener('childComponentsLoaded', () => {
        childComponentsLoaded++;
        if(childComponentsLoaded >= childComponentsToLoad){
            setTimeout(() => hideLoader(), 500);
        }
    }, false)

    return (
        userData &&
        <div className="live-page-wrapper container-fluid">
            <main className="live-page live-page--template-two my-md-5 my-4">
                <div className="row">
                    <div className="col-lg-8 mt-md-4">
                        <LivePageVideo userData={userData} groupData={groupData} />
                        <div className="row mt-md-5 mt-4">
                            <div className="col-md-4">
                                <LivePageAgenda userData={userData} groupData={groupData} />
                            </div>
                            <div className="col-md-8 mt-md-0 mt-3">
                                <LivePagePolls />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-lg-0 mt-3">
                        <LivePageQuestionChat />
                    </div>
                </div>
                <div className="row mt-md-5 mt-4">
                    <div className="col-12">
                        <LivePageDocuments />
                    </div>
                </div>
            </main>
        </div>
    )

}

export default LivePageTemplateTwo;