import axios from './axios';
import MemberLog from '../Models/Log/MemberLog';
import { WebSocketTypeRes } from '../Models/ResponseModels/WebSocketModels';
import { toast } from 'react-toastify';


// Use forEach with async functions to wait 
export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

// Scroll top to element with specific id
export function scrollToElementWithId(id: string, yOffset: number = 0, container?: any) {
  const element = document.getElementById(id) as HTMLElement;
  if (element) {
    if (container) {
      container.scrollTop = element.offsetTop + yOffset;
    } else {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}

// Detects if mobile device is iOS 
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}

// Detects if mobile device
export const isMobileDevice = () => {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

// Detects if device is in standalone mode (=> PWA installed)
// @ts-ignore
export const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true);

// Detects if browser is IE
export const isIE = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return userAgent.indexOf("msie ") > -1 || userAgent.indexOf("trident/") > -1;
}


// AddDays to a specific date
export const addDays = (date: Date, numDaysToAdd: number) => {
  var dateNow = new Date();
  var finalDate = dateNow.setDate(date.getDate() + numDaysToAdd);
  return new Date(finalDate);
}

// RemoveDays from a specific date
export const removeDays = (date: Date, numDaysToRemove: number) => {
  var dateNow = new Date();
  var finalDate = dateNow.setDate(date.getDate() - numDaysToRemove);
  return new Date(finalDate);
}


// Remove HTML tags from a string
export const cleanText = (text: string) => {
  return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&\/?[^]+(;)/g, "");
}

export const truncateText = (text: string, maxChars: number) => {
  let formattedText = text;
  if (text.length >= maxChars) {
    formattedText = text.substr(0, maxChars - 5).concat('...')
  }
  return formattedText;
}

export const formatFileSize = (size: number) => {
  var fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
  var i = 0;
  while (size > 900) {
    size /= 1024;
    i++;
  }

  var exactSize = (Math.round(size * 100) / 100) + ' ' + fSExt[i];
  return exactSize;
}

export const saveLog = (data: MemberLog, async = true) => {
  if (async) {
    axios.post('Logs/SaveLog', data).then(res => console.log).catch(e => console.log(e));
  }
  else {
    const token = window.storageGetItemValue("Auth-token");
    var xhr = new XMLHttpRequest();
    xhr.open('POST', window.apiURL + 'Logs/SaveLog', false);
    xhr.setRequestHeader("Content-type", "application/json")
    xhr.setRequestHeader("Authorization", token);
    xhr.send(JSON.stringify(data));
  }
}


// loader
const loader: any = document.getElementById('page-loader');

export function showLoader() {
  loader.classList.remove('available');
}

export function hideLoader() {
  loader.classList.add('available');
}


// Validate fields

export const validateEmail = (email: string) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
    return true;
  return false;
}

export const validatePassword = (psw: string) => {
  if (psw.length >= 8 && psw.length <= 50)
    return true
  return false
}


// CREATE AND DOWNLOAD CSV FILE


// Create file from data and download it
// * data: json
// * filename: name.ext
// * type: type of the file to download
export function downloadDataIntoCsv(data: any, filename: string, type: string) {
  var fileData = convertJsonToCsv(data);
  var file = new Blob(["\ufeff" + fileData], { type: type });
  if (window.navigator.msSaveOrOpenBlob) // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else { // Others
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

// Receive a json and returns a string in csv format
function convertJsonToCsv(json: any) {
  const JOINCHAR = ';';
  var fields = Object.keys(json[0])
  var replacer = function (key: any, value: any) { return value === null ? '' : value }
  var csv = json.map(function (row: any) {
    return fields.map(function (fieldName) {
      return JSON.stringify(row[fieldName], replacer)
    }).join(JOINCHAR)
  })
  csv.unshift(fields.join(JOINCHAR)) // add header column
  return (csv.join('\r\n'));
}



// Force portrait or landscape when printing
export function forcePagePrintDirection(size: string) {
  var css = '@page { size: ' + size + ' }',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

  style.type = 'text/css';
  style.media = 'print';

  //@ts-ignore
  if (style.styleSheet) {
    //@ts-ignore
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  head.appendChild(style);
}


/* COOKIES */

// Set cookie
export function setCookie(name: string, value: string, hours: number) {
  var expires = "";
  if (hours) {
    var date = new Date();
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// Get cookie
export function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// Remove cookie
export function removeCookie(name: string) {
  document.cookie = name + '=; Max-Age=-99999999;';
}

// Manually add offset to UTC date based on a specific timezone
export function formatUTCDateWithOffset(date: Date, secondsOffset: number) {
  const timeUTCdate = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()) + date.getTimezoneOffset() * 60000;
  var hours = Math.floor(secondsOffset / 60)
  var minutes = secondsOffset % 60;
  var newDateInMS = (timeUTCdate + (hours * 3600 * 1000) + (minutes * 60 * 1000));
  return new Date(newDateInMS)
}



/* FILES CONVERSION */

// Convert file image to base 64 string. return a promise with the value
export function fileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        // var encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        // if ((encoded.length % 4) > 0) {
        //   encoded += '='.repeat(4 - (encoded.length % 4));
        // }
        resolve(reader.result);
      } else {
        reject('');
      }
    };
    reader.onerror = error => reject('');
  });
}

// Convert a base64 image to file
export const dataURLtoFile = (dataurl: string, filename: string) => {

  var arr = dataurl.split(','),
    //@ts-ignore
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  console.log(filename, mime);
  var file = new File([u8arr], filename, { type: mime });

  return file;
}

// From https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob, needed for Safari:
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback: any, type: any, quality: any) {

      var binStr = atob(this.toDataURL(type, quality).split(',')[1]),
        len = binStr.length,
        arr = new Uint8Array(len);

      for (var i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }

      callback(new Blob([arr], { type: type || 'image/png' }));
    }
  });
}

window.URL = window.URL || window.webkitURL;

// Modified from https://stackoverflow.com/a/32490603, cc by-sa 3.0
// -2 = not jpeg, -1 = no data, 1..8 = orientations
function getExifOrientation(file: any, callback: any) {
  // Suggestion from http://code.flickr.net/2012/06/01/parsing-exif-client-side-using-javascript-2/:
  if (file.slice) {
    file = file.slice(0, 131072);
  } else if (file.webkitSlice) {
    file = file.webkitSlice(0, 131072);
  }

  var reader = new FileReader();
  reader.onload = function (e: any) {
    var view = new DataView(e.target.result);
    if (view.getUint16(0, false) != 0xFFD8) {
      callback(-2);
      return;
    }
    var length = view.byteLength, offset = 2;
    while (offset < length) {
      var marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xFFE1) {
        if (view.getUint32(offset += 2, false) != 0x45786966) {
          callback(-1);
          return;
        }
        var little = view.getUint16(offset += 6, false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;
        for (var i = 0; i < tags; i++)
          if (view.getUint16(offset + (i * 12), little) == 0x0112) {
            callback(view.getUint16(offset + (i * 12) + 8, little));
            return;
          }
      }
      else if ((marker & 0xFF00) != 0xFF00) break;
      else offset += view.getUint16(offset, false);
    }
    callback(-1);
  };
  reader.readAsArrayBuffer(file);
}

// Derived from https://stackoverflow.com/a/40867559, cc by-sa
function imgToCanvasWithOrientation(img: any, rawWidth: number, rawHeight: number, orientation: number) {
  var canvas = document.createElement('canvas');
  if (orientation > 4) {
    canvas.width = rawHeight;
    canvas.height = rawWidth;
  } else {
    canvas.width = rawWidth;
    canvas.height = rawHeight;
  }

  if (orientation > 1) {
    console.log("EXIF orientation = " + orientation + ", rotating picture");
  }

  var ctx: any = canvas.getContext('2d');
  switch (orientation) {
    case 2: ctx.transform(-1, 0, 0, 1, rawWidth, 0); break;
    case 3: ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight); break;
    case 4: ctx.transform(1, 0, 0, -1, 0, rawHeight); break;
    case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
    case 6: ctx.transform(0, 1, -1, 0, rawHeight, 0); break;
    case 7: ctx.transform(0, -1, -1, 0, rawHeight, rawWidth); break;
    case 8: ctx.transform(0, -1, 1, 0, 0, rawWidth); break;
  }
  ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
  return canvas;
}

export function reduceFileSize(file: File, acceptFileSize: number, maxWidth: number, maxHeight: number, quality: number, callback: any) {
  if (file.size <= acceptFileSize) {
    callback(file);
    return;
  }
  var img = new Image();
  img.onerror = function () {
    URL.revokeObjectURL(this.src);
    callback(file);
  };
  img.onload = function () {
    // @ts-ignore
    URL.revokeObjectURL(this.src);
    getExifOrientation(file, function (orientation: any) {
      var w = img.width, h = img.height;
      var scale = (orientation > 4 ?
        Math.min(maxHeight / w, maxWidth / h, 1) :
        Math.min(maxWidth / w, maxHeight / h, 1));
      h = Math.round(h * scale);
      w = Math.round(w * scale);

      var canvas = imgToCanvasWithOrientation(img, w, h, orientation);
      canvas.toBlob(function (blob: any) {
        console.log("Resized image to " + w + "x" + h + ", " + (blob.size >> 10) + "kB");
        callback(blob);
      }, 'image/jpeg', quality);
    });
  };
  img.src = URL.createObjectURL(file);
}



/*  WEB SOCKET */

export const wsSetup = (userName: string, chatRoomID: string) => {
  console.log("Trying to estabilish connection to Websocket:", window.wsURL, userName, chatRoomID);
  window.webSocket = new WebSocket(window.wsURL);
  window.webSocket.onopen = (ev: any) => {
    window.dispatchEvent(new CustomEvent('webSocketOpen'));
  }
  window.webSocket.onerror = (ev: any) => {
    console.log("Socket error!", ev);
    //setTimeout(wsSetup, 5000); //trying to restabilish connection
  }

  window.webSocket.onclose = (ev: any) => {
    console.log("Websocket connection closed", ev);
    let token = window.storageGetItemValue("Auth-token");
    if (token != null) {
      //calculating random time to retry between 5 and 30 secs
      var mseconds = 0;
      if (window.wsAttempts < 5) {
        mseconds = Math.floor(Math.random() * (30000 - 5000) + 5000);
      }
      else {
        //i tried connection 10 times, now I retry in 30 minutes
        mseconds = (window.debug) ? 60000 * 2 : 60000 * 30; //30min
        window.wsAttempts = 0;
        // showErrorMessage("We are noticing some connections problem. Maybe your VPN connection is active? Please log off from your VPN for better user experience.");
      }
      if (window.debug) console.log("Trying to reconnect WS in ms: " + mseconds);
      window.wsReconnectTo = setTimeout(function () {
        if (window.debug) console.log("WSSetup called by wsReconnect");
        if (window.webSocket == null || window.webSocket.readyState === WebSocket.CLOSED) {
          window.wsAttempts++;
          console.log("Incremented wsAttempts: " + window.wsAttempts);
          wsSetup(userName, chatRoomID);
        }
      }, mseconds); //trying to restabilish connection
    }
  }

  window.webSocket.onmessage = (ev: any) => {

    var message: WebSocketTypeRes.IWsMessage = JSON.parse(ev.data) as WebSocketTypeRes.IWsMessage;
    if (window.debug) console.log("message received from WS", message);

    switch (message.action) {
      case "Welcome":
        if (window.debug) console.log("WELCOME RECEIVED!!!!");
        wsSendLogin(userName);
        break;
      case "LoginSuccess":
        if (window.debug) console.log("User logged: joining rooms;")
        //I'm connected, trying to join rooms:
        // wsJoinRoom(window.pollRoom); // polls room
        wsJoinRoom(window.chatRoomLive);
        if (window.location.href.indexOf('faculty-panel') >= 0) {
          wsJoinRoom(window.chatRoomBackstage);
        }
        wsKeepAlive();
        break;
      case "LoginFailed":
        if (window.debug) console.log("User login FAILED!!! Closing connection")
        var newLog: MemberLog = new MemberLog("/", "WS_LoginFailed", "EMEA Town Hall - 2024-09-23", (window.storageGetItemValue("Auth-name") || "") + " " + (window.storageGetItemValue("Auth-lastName") || ""), "", window.navigator.userAgent);
        saveLog(newLog);
        window.webSocket.close();
        window.webSocket = null;
        setTimeout(function () {
          console.log("LoginFailed", "clearing timeout");
          clearTimeout(window.wsReconnectTo);
        }, 500);
        break;

      case "Command":
        var commandMessage: WebSocketTypeRes.IwsCommand = message.message as WebSocketTypeRes.IwsCommand;
        wsEvaluateCommand(commandMessage);
        break;
      case "Message":
        console.log(message);
        window.dispatchEvent(new CustomEvent('loadChatMessage', { "detail": { message: message.message } }));
        break;
      case "Error":
        console.log("Error from websocket!", message.message)
        if (message.message === 'Access Denied') {
          //TODO: need to refresh the token?
        }
    }
  }

}

export const wsEvaluateCommand = (commandMessage: WebSocketTypeRes.IwsCommand) => {
  if (window.debug) console.log("Evaluate section", commandMessage.section);
  switch (commandMessage.section) {
    case "Poll":
      wsPollCommandExecution(commandMessage);
      break;
    case "Chat":
      wsChatCommandExecution(commandMessage);
      break;
    case "Event":
      wsEventCommandExecution(commandMessage);
      break;
    case "UICommand":
      wsUICommandExecution(commandMessage);
      break;
  }
}

export const wsPollCommandExecution = (commandMessage: WebSocketTypeRes.IwsCommand,) => {
  if (window.debug) console.log("WsPollCommandExecution", commandMessage);
  var pollAction: WebSocketTypeRes.IWsPollActions = commandMessage.command as WebSocketTypeRes.IWsPollActions;
  var myEvent: CustomEvent = new CustomEvent('loadPollChart');

  switch (pollAction) {
    case "DisableQuestion":
    case "EnableQuestion":
    case "ClearQuestion":
      if (window.debug) console.log("wsPollCommandExecution: EnableQuestion")
      var data: WebSocketTypeRes.IWsPollQuestion = commandMessage.value as WebSocketTypeRes.IWsPollQuestion;
      myEvent = new CustomEvent("loadPollData", { "detail": { pollID: data.PollID } });
      window.dispatchEvent(myEvent);
      break;
    case "ClearPoll":
      if (window.debug) console.log("wsPollCommandExecution")
      window.dispatchEvent(new CustomEvent('loadPollData'));
      break;
    case "SetPoll":
      if (window.debug) console.log("wsPollCommandExecution: SetPoll")
      myEvent = new CustomEvent("loadPollData", { "detail": { pollID: commandMessage.value } });
      window.dispatchEvent(myEvent);
      break;

    case "Vote":
      if (commandMessage.value === "OK") {
        window.dispatchEvent(new CustomEvent('setAlreadyAnswered'));
      }
      break;
    case "VoteReceived":
      if (window.debug) console.log("wsPollCommandExecution: VoteReceived")
      window.dispatchEvent(new CustomEvent('loadPollChart'));

      //if voteReceived is my vote then...
      break;
  }
}

export const wsChatCommandExecution = (commandMessage: WebSocketTypeRes.IwsCommand) => {
  if (window.debug) console.log("wsChatCommandExecution", commandMessage);
  var msgAction: WebSocketTypeRes.IWsChatActions = commandMessage.command as WebSocketTypeRes.IWsChatActions;

  switch (msgAction) {
    case "DeleteMessage":
      window.dispatchEvent(new CustomEvent('deleteMessage', { "detail": { messageID: commandMessage.value } }));
      break;
    case "ArchiveMessage":
      window.dispatchEvent(new CustomEvent('archiveMessage', { "detail": { messageID: commandMessage.value } }));
      break;
    case "VoteMessage":
      window.dispatchEvent(new CustomEvent('voteMessage', { "detail": { messageID: commandMessage.value } }));
      break;
    case "UnVoteMessage":
      window.dispatchEvent(new CustomEvent('unVoteMessage', { "detail": { messageID: commandMessage.value } }));
      break;
    // case "BanFromRoom":
    // case "UNBanFromRoom":
  }

}

export const wsEventCommandExecution = (commandMessage: WebSocketTypeRes.IwsCommand) => {
  if (window.debug) console.log("wsEventCommandExecution", commandMessage);
  var msgAction: WebSocketTypeRes.IWsEventActions = commandMessage.command as WebSocketTypeRes.IWsEventActions;

  switch (msgAction) {
    case "EnableEvent":
      window.dispatchEvent(new CustomEvent('enableEvent', { "detail": { eventID: commandMessage.value } }));
      break;
    case "DisableEvent":
      window.dispatchEvent(new CustomEvent('disableEvent', { "detail": { eventID: commandMessage.value } }));
      break;
  }
}

export const wsOpenWindowCommandExecution = (commandMessage: WebSocketTypeRes.IwsCommand) => {
  if (window.debug) console.log("wsOpenWindowCommandExecution", commandMessage);
  window.dispatchEvent(new CustomEvent('openBreakoutRoom', { "detail": { value: commandMessage.value } }));
}

export const wsUICommandExecution = (commandMessage: WebSocketTypeRes.IwsCommand) => {
  if (window.debug) console.log("wsUICommandExecution", commandMessage);
  var msgAction: WebSocketTypeRes.IWsUIEffectActions = commandMessage.command as WebSocketTypeRes.IWsUIEffectActions;

  switch (msgAction) {
    case "OpenWindow":
      window.dispatchEvent(new CustomEvent('openWindow', { "detail": { value: commandMessage.value } }));
      break;
    case "ShowEffect":
      window.dispatchEvent(new CustomEvent('ShowEffect', { "detail": { value: commandMessage.value } }));
      break;
  }
}

export const wsJoinRoom = (roomID: string) => {
  if (window.debug) console.log("WEBSOCKET: Joining room " + roomID);
  var data: WebSocketTypeRes.IWsMessage = {
    action: "JoinRoom",
    message: "",
    roomID: roomID
  }
  wsSend(data);
}

export const wsKeepAlive = () => {
  var timeout = 20000;
  var token = window.storageGetItemValue("Auth-token");
  if (window.webSocket != null) {
    if (window.webSocket.readyState === window.webSocket.OPEN && token != null) {
      if (window.debug) console.log("wsKeepAlive: sending current token...");
      var message: WebSocketTypeRes.IWsMessage = {
        action: "KeepAlive",
        message: token,
        roomID: ""
      };
      wsSend(message);
      setTimeout(wsKeepAlive, timeout);
    }
  }
}

export const wsSendLogin = (userName: string) => {
  //Authenticate
  var login = {
    _token: window.storageGetItemValue('Auth-token'),
    email: '',
    password: '',
    userName: userName,
  };
  var data: WebSocketTypeRes.IWsMessage = {
    action: "Login",
    message: login,
    roomID: ''
  };
  if (window.debug) console.log("wsSendLogin", data)
  wsSend(data);
}

export const liveAnswerSubmit = (data: WebSocketTypeRes.IWsPollVote, roomID: string) => {
  let action: WebSocketTypeRes.IWsPollActions = "Vote";
  let command: WebSocketTypeRes.IwsCommand = {
    section: "Poll",
    command: action as string,
    value: data
  }
  var message: WebSocketTypeRes.IWsMessage = {
    action: "Command",
    message: command,
    roomID: roomID
  }
  wsSend(message);
  var newLog: MemberLog = new MemberLog("/", "PollAnswerSubmit", "EMEA Town Hall - 2024-09-23", "PollAnswer", "", JSON.stringify(command));
  saveLog(newLog);

}
// values example: 
// { name: 'confetti', active: true }
export const setUIEffect = (roomID: string, commandValue: { name: string, active: boolean }) => {
  if (window.debug) console.log('Start/Stop specific UI effect');
  let action: WebSocketTypeRes.IWsUIEffectActions = "ShowEffect";
  let command: WebSocketTypeRes.IwsCommand = {
    section: "UICommand",
    command: action as string,
    value: commandValue
  }
  var message: WebSocketTypeRes.IWsMessage = {
    action: "Command",
    message: command,
    roomID: roomID
  }
  const eventTitle = 'Town Hall';
  wsSend(message);
  var newLog: MemberLog = new MemberLog("/", "ShowEffectSubmit", eventTitle, "ShowEffect", "", JSON.stringify(command));
  saveLog(newLog);
}

export const messageSubmit = (data: WebSocketTypeRes.IWsChatMessage, chatRoomID: string = window.chatRoomLive) => {
  var message: WebSocketTypeRes.IWsMessage = {
    action: "Message",
    message: data,
    roomID: chatRoomID
  }
  wsSend(message);
}

export const wsSend = (data: WebSocketTypeRes.IWsMessage) => {
  window.webSocket.send(JSON.stringify(data));
}

/* TOASTER Messages */
export const showWarningMessage = (message: string) => {
  toast.warning(message,
    {
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return;
}

export const showSuccessMessage = (message: string) => {
  toast.success(message,
    {
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return;
}

export const showErrorMessage = (message: string) => {
  toast.error(message,
    {
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return;
}

export const showInfoMessage = (message: string) => {
  toast.info(message,
    {
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return;
}

// STORAGE UTILS

class StorageUtilsClass {
  SetItem = (key: string, value: any, isPermanent?: boolean) => {
    var timestamp = new Date().getTime();
    if (key === 'indexedDBRef' || isPermanent) {
      timestamp = new Date('2999-01-01').getTime()
    }
    try {
      localStorage.setItem(key, JSON.stringify({ value: value, timestamp: timestamp }));
    } catch (e) {
      // Quota exceeded: remove oldest API call saved and try again
      //this.storageDeleteOldestItem();
      this.SetItem(key, value);
    }
  }

  GetItem = (key: string) => {
    if (localStorage.getItem(key)) {
      try {
        return JSON.parse(localStorage.getItem(key) || '[]');
      } catch (e) {
        return localStorage.getItem(key);
      }
    } else {
      return null
    }
  }

  GetItemValue = (key: string) => {
    const item = this.GetItem(key);
    if (item === null) return null;
    if (item.value === undefined) return item;
    try {
      return JSON.parse(item.value);
    } catch (e) {
      return item.value;
    }
  }

  RemoveItem = (key: string) => {
    localStorage.removeItem(key);
  }
}
export const StorageUtils = new StorageUtilsClass();
