import React from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

import { formatUTCDateWithOffset } from '../../config/functions';
import { EventsTypeRes } from '../../Models/ResponseModels/Events';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';

interface IProps {
    event: EventsTypeRes.IEvent,
    timezone: string,
    userData: MembersTypeRes.IMember,
    groupData: MembersTypeRes.IGroupAssociation | null
}

const LivePageAgendaItem = ({ event, timezone, userData, groupData }:IProps) => {

    let secondsOffset = 0;
    switch(timezone){
        case "emea":
            secondsOffset = 120;
            break;
        case "us-east-coast":
            secondsOffset = -240;
            break;
        case "us-west-coast":
            secondsOffset = -420;
            break;
        case "apac":
            secondsOffset = 480;
            break;
        case "russia":
            secondsOffset = 180;
            break;
    }
    // console.log("event.startDate", event.startDate);
    const eventStartDate = formatUTCDateWithOffset(new Date(event.startDate), secondsOffset)

    let titleStyle = {};
    if(event.additionalField02){
        titleStyle = { color: event.additionalField02 }
    }

    let activeStyleContainer = {};
    let activeStyleTitle = {};
    if(event.additionalField02 && event.active){
        if(event.additionalField02 !== '#FFFFFF'){
            activeStyleContainer = { backgroundColor: event.additionalField02 }
            activeStyleTitle = { color: '#fff' }
        }
    }

    // setting MT link if available (from event)
    let linkMT;
    if(event.additionalField03 !== null ){
        linkMT = event.additionalField03;
    }

    // if(event.additionalField03 === 'LINK' && userData.additionalField03){
    //     linkMT = userData.additionalField03.split("|")[4]
    // }

    let linkImage;
    if(event.additionalField02){
        switch(event.additionalField02){
            case '#9c9c98':
                linkImage = 'ball-gray.png'; 
                break;
            case '#95C11F':
                linkImage = 'ball-green.png';
                break;
            case '#EA5297':
                linkImage = 'ball-pink.png';
                break;  
            case '#0BBBEF':
                linkImage = 'ball-blue.png';
                break;
            case '#FFCC00':
                linkImage = 'ball-yellow.png';
                break; 
        }
    }

    let eventTitle = event.title;
    if(event.additionalField01 && event.additionalField01.indexOf("AMER") >= 0){
        if(eventTitle.split("|").length > 1){
            if(timezone === "us-east-coast"){
                eventTitle = eventTitle.split("|")[0]
            } else if(timezone === "us-west-coast"){
                eventTitle = eventTitle.split("|")[1]
            }
        }
    }

    return (
        <>
            <div id={event.eventID} className={"live-page-agenda-item text-center"+(event.active ? ' active' : '')}>
                <header className="live-page-agenda-item__header">
                    {
                        event.active &&
                        <span className="live-page-agenda-item__header-icon">&bull;</span>
                    }
                    {/* <p className="live-page-agenda-item__time u-font-size-10">xx:xx</p> */}
                    {/* <p className="live-page-agenda-item__time u-font-size-10">{moment(eventStartDate).format('HH:mm')}</p> */}
                </header>
                <h1 className="live-page-agenda-item__title u-font-size-10">
                    {/* <span className="u-font-size-25">▸</span> */}
                    {ReactHtmlParser(eventTitle)}
                </h1>
                <h2 className="live-page-agenda-item__descr u-font-size-9">{ReactHtmlParser(event.description)}</h2>
                <h2 className="live-page-agenda-item__author u-font-size-8">{ReactHtmlParser(event.notes)}</h2>
                <div className="live-page-agenda-item__btn-wrapper" >
                    { linkImage && linkMT && <a className="live-page-agenda-item__btn btn btn-primary u-font-size-8" href={linkMT} target="_blank">Open in MS Teams</a> }
                </div>
            </div>
            <hr className="live-page-agenda-separator" />
        </>
    )

}

export default LivePageAgendaItem;



