import React, { useEffect, useContext, useState } from "react";
import { ContextAppSettings } from "../../config/context";
import { translations } from "../../config/translations";

import Button from "../_UI/Button/Button";
import Interprefy from "../Interprefy/Interprefy";
import { getZbTvSignature } from "../../config/zbtv";
import { MembersTypeRes } from "../../Models/ResponseModels/Members";

interface IProps {
    userData: MembersTypeRes.IMember,        // TODO: remove | null
    groupData: MembersTypeRes.IGroupAssociation | null,
}
const expDate = Math.floor(Date.now() / 1000) + 86400;
const LivePageVideo = ({ userData, groupData }: IProps) => {
  const { language } = useContext(ContextAppSettings);

  const [isInterprefyActive, setInterprefyActive] = useState<boolean>(false);
  // const [isInterprefyActive, setInterprefy] = useState<boolean>(false);
  const [isInterprefyVisible, setInterprefyVisibility] = useState<boolean>(false);
  const [signature, setSignature] = useState("");

  const key = process.env.REACT_APP_ZBTV_KEY;
  const input = `${process.env.REACT_APP_ZBTV_EVENT_ID}${userData.email}${expDate.toString()}`;

  useEffect(() => {
    // -- save log
    // axiosInstance.get('Contents/heidelberger-uni-knie-kurs-online');
    console.log("LivePageVideo - childComponentsLoaded");
    window.dispatchEvent(new CustomEvent("childComponentsLoaded"));

    getSignature();
  }, []);

  async function getSignature() {
    const signature = await getZbTvSignature(key, input);
    setSignature(signature);
  }

  return (
    <section className="live-page-video-wrapper">
      <div className="live-page-video">
        {/* <h2 className="live-page-video__title u-font-size-18">EMEA Town Hall – 2021 Kick Off</h2>
                <h3 className="live-page-video__subtitle u-font-size-12">18 May, 10:30am – 12:00pm</h3> */}
        <div className="live-page-video__frame-wrapper container-video-21-9 container-vm-iframe">
          {/* <iframe id="ls_embed_1630942373" src="https://livestream.com/accounts/22298103/events/9830178/videos/225698012/player?width=1920&height=1080&enableInfo=true&defaultDrawer=feed&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1630942373" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          {/* <iframe id="ls_embed_1643711588" src="https://livestream.com/accounts/22298103/events/10132468/player?width=1920&height=1080&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1643711588" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          {/* <iframe src="https://player.vimeo.com/video/675896578?h=59accaf0d6&autoplay=1" width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
          {/* <iframe src="https://player.vimeo.com/video/675951434?h=396394a7d1&autoplay=1" width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
          {/* <iframe id="ls_embed_1651754157" src="https://livestream.com/accounts/22298103/events/10385475/player?width=1920&height=1080&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1651754157" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          {/* <iframe id="ls_embed_1652879791" src="https://livestream.com/accounts/22298103/events/10385475/videos/231183172/player?width=1920&height=1080&enableInfo=false&defaultDrawer=feed&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1652879791" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          {/* <iframe id="ls_embed_1657005524" src="https://livestream.com/accounts/22298103/events/10532412/player?width=1920&height=1080&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1657005524" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          {/* <iframe src="https://player.vimeo.com/video/729605387?h=d6a3a4cc4e&autoplay=1" width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
          {/* <iframe id="ls_embed_1661935977" src="https://livestream.com/accounts/22298103/events/10599710/player?width=1920&height=1080&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1661935977" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          {/* <iframe src="https://player.vimeo.com/video/749970678?h=c5d82ce39d&autoplay=1" width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
          {/* <iframe id="ls_embed_1667209744" src="https://livestream.com/accounts/22298103/events/10667313/player?width=1920&height=1080&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1667209744" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          {/* <iframe src="https://player.vimeo.com/video/772036451?h=146a66fdc8&autoplay=1" width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
          {/* <iframe id="ls_embed_1675172581" src="https://livestream.com/accounts/22298103/events/10753305/player?width=1920&height=1080&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1675172581" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          {/* <iframe src="https://player.vimeo.com/video/797366498?h=c675d528f3&autoplay=1" width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}
          {/* <iframe id="ls_embed_1683030808" src="https://livestream.com/accounts/22298103/events/10848140/player?width=1920&height=1080&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1683030808" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          {/* <iframe src="https://player.vimeo.com/video/825449201?h=54c1f6ef10&autoplay=1" width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe> */}

          {/* {signature && <iframe src={`https://staging.live.zimmerbiomet.tv/events/${process.env.REACT_APP_ZBTV_EVENT_ID}/embed?user=${userData.email}&expires=${expDate.toString()}&signature=${signature.toUpperCase()}`} style={{ width: "100%", height: "100%" }} allowFullScreen frameBorder="0"></iframe>} */}



          {/* <iframe id="ls_embed_1695816820" src="https://livestream.com/accounts/22298103/events/10974448/player?width=1920&height=1080&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1695816820" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          {signature && <iframe src={`https://live.zimmerbiomet.tv/events/${process.env.REACT_APP_ZBTV_EVENT_ID}/embed?user=${userData.email}&expires=${expDate.toString()}&signature=${signature.toUpperCase()}`} style={{ width: "100%", height: "100%" }} allowFullScreen frameBorder="0"></iframe>}
          {/* <iframe id="ls_embed_1701163381" src="https://livestream.com/accounts/22298103/events/11031069/player?width=1920&height=1080&enableInfoAndActivity=false&defaultDrawer=&autoPlay=true&mute=false" width="1920" height="1080" frameBorder="0" scrolling="no" allowFullScreen> </iframe><script type="text/javascript" data-embed_id="ls_embed_1701163381" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script> */}
          
        </div>
        {/* <div className="live-page-video__translate">
          {!isInterprefyActive ? (
            <Button
              icon="faGlobeEurope"
              iconPosition="left"
              withClass={["secondary"]}
              classes="u-font-size-10 font-weight-bold mt-2"
              clicked={() => {
                setInterprefyActive(true);
                setInterprefyVisibility(true);
              }}
            >
              Load interpreter
            </Button>
          ) : (
            <div className="live-page-video__interprefy-wrapper col-lg-4 mt-lg-0 mt-3">
              <div className="live-page-video__interprefy-width"></div>
              <div className="live-page-video__interprefy-buttons">
                {isInterprefyVisible ? (
                  <button className="btn-interprefy btn-hide" onClick={() => setInterprefyVisibility(false)}>
                    HIDE
                  </button>
                ) : (
                  <button className="btn-interprefy btn-show" onClick={() => setInterprefyVisibility(true)}>
                    SHOW
                  </button>
                )}
                <button className="btn-interprefy btn-stop" onClick={() => setInterprefyActive(false)}>
                  STOP
                </button>
              </div>
              <div className={"live-page-video__interprefy" + (isInterprefyVisible ? "" : " d-none")}>
                <Interprefy />
              </div>
            </div>
          )}
        </div> */}
      </div>
    </section>
  );
};

export default LivePageVideo;
