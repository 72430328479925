import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileVideo, faFileAudio, faFileImage, faLink } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser from 'react-html-parser';
import axiosInstance from '../../config/axios';
import history from '../../config/history';
import { ResourcesTypeRes } from '../../Models/ResponseModels/Resources';
import LivePageSectionHeader from './LivePageSectionTitle';

// TODO: Use friendlyUrl
const resourceCategoryID = 10;

const LivePageDocuments = () => {

    const [resourceCategory, setResourceCategory] = useState<ResourcesTypeRes.IResourceCategory | null>(null);
    const [resources, setResources] = useState<ResourcesTypeRes.IResource[]>([]);

    useEffect(() => {
        axiosInstance.get('Resources/Category/' + resourceCategoryID).then(res => {
            const response = res.data;
            if (response.status === "success") {
                setResourceCategory(response.data);
                axiosInstance.get('Resources/Category/' + resourceCategoryID + '/Resources').then(res => {
                    const response = res.data;
                    if (response.status === "success") {
                        setResources(response.data.collection);
                    }
                    console.log("LivePageDocuments - childComponentsLoaded")
                    window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
                })
            } else {
                console.log("LivePageDocuments - childComponentsLoaded")
                window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
            }
        })
    }, [])

    const handleOpenDocument = (resource: ResourcesTypeRes.IResource) => {
        if (resource.resourceTypeID === 'LINK') {
            resource.isExternal ? window.open(resource.link) : history.push(resource.link);
        }
        //@ts-ignore
        if (resource.resourceTypeID === 'DOCUMENT' || resource.resourceTypeID === 'IMAGE') {
            window.open(window.apiURL + "Resources/" + resource.resourceID + "/Visualize/");
        }
        if (resource.resourceTypeID === 'VIDEO') {
            window.open(window.apiURL + "Resources/" + resource.resourceID + "/Download/");
        }
    }

    return (
        resourceCategory &&
        <section className="live-page-documents">
            <div className="live-page-documents-header">

                {/* <h3 className="live-page-documents__title u-font-size-18">Related documents</h3>
                <hr className="live-page-documents__separator" />
                <p className="live-page-documents__subtitle u-font-size-12">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec fringilla purus, vel commodo orci. Phasellus ligula diam, imperdiet non varius ut, efficitur ac nibh.
                </p> */}

                <LivePageSectionHeader
                    title={resourceCategory.resourceCategoryName}
                    imgSource="assets/icons/related-docs_icon.png"
                />

                {/* <h3 className="live-page-documents__title u-font-size-18">{resourceCategory.resourceCategoryName}</h3>
                <hr className="live-page-documents__separator" />
                {
                    resourceCategory.resourceCategoryDescription &&
                    <p className="live-page-documents__subtitle u-font-size-12">{resourceCategory.resourceCategoryDescription}</p>
                } */}

            </div>
            <div className="live-page-documents-list row">
                {
                    resources && resources.length > 0 ?
                        resources.map(resource => {
                            const title = resource.title;
                            const icon = resource.resourceTypeID === 'DOCUMENT' ? faFilePdf :
                                (resource.resourceTypeID === 'AUDIO' ? faFileAudio :
                                    (resource.resourceTypeID === 'IMAGE' ? faFileImage :
                                        (resource.resourceTypeID === 'VIDEO' ? faFileVideo :
                                            (faLink)
                                        )))
                            return (
                                <div key={resource.resourceID} className="live-page-documents-item-wrapper col-md-6">
                                    <div className="live-page-documents-item" onClick={() => handleOpenDocument(resource)}>
                                        <FontAwesomeIcon className="live-page-documents-item__icon" icon={icon} />
                                        <p className="live-page-documents-item__text u-font-size-10">{ReactHtmlParser(title)}</p>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <h3 className="u-font-size-15">No documents available at the moment</h3>
                }

                {/* FAKE DOCS */}
                {/* <div className="col-md-6">
                    <div className="live-page-documents-item" onClick={() => window.open('/assets/docs/zb-privacy-policy-zbemeapulse.pdf','_blank')}>
                        <FontAwesomeIcon className="live-page-documents-item__icon" icon={faFilePdf} />
                        <p className="live-page-documents-item__text u-font-size-9">Privacy Policy</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="live-page-documents-item" onClick={() => window.open('/assets/docs/zb-privacy-policy-zbemeapulse.pdf','_blank')}>
                        <FontAwesomeIcon className="live-page-documents-item__icon" icon={faFilePdf} />
                        <p className="live-page-documents-item__text u-font-size-9">Privacy Policy</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="live-page-documents-item" onClick={() => window.open('/assets/docs/zb-privacy-policy-zbemeapulse.pdf','_blank')}>
                        <FontAwesomeIcon className="live-page-documents-item__icon" icon={faFilePdf} />
                        <p className="live-page-documents-item__text u-font-size-9">Privacy Policy</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="live-page-documents-item" onClick={() => window.open('/assets/docs/zb-privacy-policy-zbemeapulse.pdf','_blank')}>
                        <FontAwesomeIcon className="live-page-documents-item__icon" icon={faFilePdf} />
                        <p className="live-page-documents-item__text u-font-size-9">Privacy Policy</p>
                    </div>
                </div> */}

            </div>
        </section>
    )

}

export default LivePageDocuments;