import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";

import axios from '../../config/axios';
import history from '../../config/history';
import { validateEmail, validatePassword, hideLoader, showLoader } from '../../config/functions'
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import { TagsTypeRes } from '../../Models/Tags';

import Button from '../../Components/_UI/Button/Button';
import EventHeader from '../../Components/EventHeader/EventHeader';
import Countdown from '../../Components/Countdown/Countdown';
import WarningIE from '../../Components/WarningIE/Warning';


interface IProps {
    type: 'new' | 'edit',
    isRegistrationEnabled: boolean,
}

interface IFormValues {
    first_access: string,
    firstName: string,
    lastName: string,
    email: string,
    country: string,
    password: string,
    terms: boolean,
    approved: boolean,
    registeredFrom: string | null,
    captcha: string
}

const initialValues: IFormValues = {
    first_access: 'N',
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    password: '',
    terms: false,
    approved: false,
    registeredFrom: null,
    captcha: ''
}
let memberID: number;
let thumbnailWidth: number = 200;


// If type = 'new' => Register new user
// If type = 'edit' & logged => Edit logged user data
// If type = 'edit' & not logged => Redirect to login

const RegisterPage = ({ type, isRegistrationEnabled }: IProps) => {

    const [showValidation, setValidation] = useState<boolean>(false);
    // When submitting form avoid executing API call twice
    const [submittingForm, setSubmittingForm] = useState<boolean>(false);
    const [statusError, setStatusError] = useState<number>(0);
    const [error, setError] = useState<string>('');
    const [countries, setCountries] = useState<{ value: string, text: string, selected?: boolean }[]>([]);
    // const [interests, setInterests] = useState<TagsTypeRes.ITag[]>([]);
    const [initValues, setInitValues] = useState<IFormValues>(initialValues);

    const smallDevice = window.matchMedia('(max-width: 575.98px)').matches;

    const [showWarningIE, setShowWarningIE] = useState(true);
    const [showFade, setShowFade] = useState(true);
    function onClickShowWarning() {
        setShowFade(!showWarningIE);
        setShowWarningIE(!showWarningIE);
    }


    useEffect(() => {

        (async function getData() {

            if (type === 'edit' && window.storageGetItemValue("Auth-token") === null) {
                history.push("/login");
            }
            if (type === 'edit') {
                showLoader();
            }

            const resCountries = await axios.get('Countries');
            const countries = resCountries.data.data.map((country: any) => {
                return { value: country.countryID, text: country.countryName }
            });
            setCountries(countries);

            // const resInterests = await axios.get('Tags/Categories/3');
            // const interests = resInterests.data.data;
            // setInterests(interests);

            if (type === 'edit') {
                axios.get('Members/GetMyProfile?id=' + new Date().getTime()).then(res => {
                    const response = res.data;
                    if (response.status === "success") {
                        const member: MembersTypeRes.IMember = response.data;
                        memberID = member.memberID;
                        // clinic / MPA / ZB
                        const clinic = member.additionalField01 !== null ? member.additionalField01 : '';
                        let MPA = '';
                        let hospital = '';
                        let ZB_Employ = '';
                        switch (member.memberTypeID) {
                            case 1 || 4:
                                hospital = clinic;
                                break;
                            case 2:
                                ZB_Employ = clinic;
                                break;
                            case 3:
                                MPA = clinic;
                                break;
                            default:
                                MPA = clinic;
                        }
                        setInitValues({
                            first_access: 'S',
                            firstName: member.name,
                            lastName: member.lastName,
                            email: member.email,
                            password: '123456789',
                            country: member.anagraphics.length > 0 && member.anagraphics[0].country ? member.anagraphics[0].country.countryID.toString() : '',
                            approved: !member.disabled,
                            terms: true,
                            registeredFrom: member.additionalField02,
                            captcha: ''
                        });
                        // interests
                        // document.getElementsByName('interests[]').forEach((interest: any) => {
                        //     interest.checked = false;
                        //     member.interests.forEach((profileInterest: any) => {
                        //         if(parseInt(interest.value) === profileInterest.tagID)
                        //             interest.checked = true;
                        //     })
                        // })
                    }
                    setTimeout(() => hideLoader(), 200);
                }).catch(e => {
                    setTimeout(() => hideLoader(), 200);
                })
            } else {
                setTimeout(() => hideLoader(), 200);
            }

        })();

    }, [])



    // https://formik.org/docs/guides/validation
    // https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript

    const signupSchema = Yup.object().shape({
        first_access: Yup.string().nullable(),
        firstName: Yup.string()
            .required('This field is required'),
        lastName: Yup.string()
            .required('This field is required'),
        country: Yup.string()
            .required('This field is required'),
        email: Yup.string()
            .required('This field is required'),
        terms: Yup.bool()
            .oneOf([true], 'This field is required'),
        approved: Yup.bool(),
        registeredFrom: Yup.string().nullable(),
    });

    const signupSchemaRegister = Yup.object({
        password: Yup.string().min(8, 'Password too short').max(50, 'Password too long').required('This field is required'),
        captcha: Yup.string().required('This field is required')
    });

    const validationSchema = type === 'new' ? signupSchema.concat(signupSchemaRegister) : signupSchema;

    const handleSubmit = async (values: any) => {

        // If i'm already calling API avoid doing it twice
        if (submittingForm) return;

        setStatusError(0);
        setError('');
        setValidation(false);
        setSubmittingForm(true);

        let formattedEmail = values.email;
        if (formattedEmail.indexOf('@mixerpa.com') < 0) {
            var rex = /(@zimmerbiomet\.com)$/;
            formattedEmail = formattedEmail.replace(rex, "");
            formattedEmail += "@zimmerbiomet.com";
        }

        const memberData: any = {
            companyID: 1,
            memberTypeID: 1,
            name: values.firstName,
            lastName: values.lastName,
            email: formattedEmail,
            termsAndConditions: values.terms,
            mailingList: false,
        }

        if (type === 'new') {
            memberData.password = values.password;
            memberData.additionalField02 = 'registered_from_town_hall';
            memberData.captcha = values.captcha;
        }

        if (type === 'edit') {
            memberData.memberID = memberID;
            memberData.disabled = !values.approved;
            memberData.additionalField02 = values.registeredFrom;
        }

        if (values.changePassword) {
            // @ts-ignore
            memberData.passwordChange = {
                email: values.email,
                userID: memberID,
                oldPassword: values.passwordOld,
                newPassword: values.passwordNew,
                newPasswordConfirm: values.passwordNew,
            }
        }

        memberData.memberAnagraphics = [{
            anagraphicID: 0,
            countryID: parseInt(values.country),
            anagraphicDescription: 'Registration',
            address: "",
            city: values.city,
            region: "",
            taxCode: "",
            vat: "",
            default: true,
            additionalField01: "",
            additionalField02: "",
            additionalField03: "",
            additionalField04: "",
            additionalField05: ""
        }];

        // Execute registration or edit profile
        if (type === 'new') {
            axios.post('Members', memberData)
                .then(res => {
                    fnSuccess(res, '/register-success');
                })
                .catch(e => {
                    fnFailed(e);
                })
        } else {
            axios.put('Members/' + memberID, memberData)
                .then(res => {
                    fnSuccess(res, '/edit-profile-success');
                })
                .catch(e => {
                    fnFailed(e);
                })
        }

    }

    const fnSuccess = async (apiResult: any, redirectPath: string) => {
        const response = apiResult.data;
        setSubmittingForm(false);
        if (response.status === 'success') {
            setStatusError(200);
            setTimeout(() => history.push(redirectPath), 500);
        } else {
            setError(response.data)
        }
    }

    const fnFailed = (error: any) => {
        const response = error.response;
        setSubmittingForm(false);
        if (response.data.status === "error") {
            setError(response.data.message)
        } else {
            setError("An error has occurred")
        }
    }



    // -- VALIDATION AND MODEL FUNCTIONS

    const formValidateEmail = (email: string) => {
        let error;
        let formattedEmail = email;
        // replace and add "@zimmerbiomet.com" (if not mixerpa.com)
        if (formattedEmail.indexOf('@mixerpa.com') < 0) {
            var rex = /(@zimmerbiomet\.com)$/;
            formattedEmail = formattedEmail.replace(rex, "");
            formattedEmail += "@zimmerbiomet.com";
            if (!validateEmail(formattedEmail)) {
                error = 'Email is not valid, please use your @zimmerbiomet.com email';
            }
        }
        return error;
    }

    const formValidatePassword = (psw: string) => {
        let error;
        if (!validatePassword(psw)) {
            error = 'Invalid password';
        }
        return error;
    }

    const formValidatePassword2 = (psw: string, mainPsw: string) => {
        let error;
        if (!validatePassword(psw)) {
            error = 'Invalid password';
        } else {
            if (psw !== mainPsw) {
                error = 'Doesn\'t match password field';
            }
        }
        return error;
    }

    return (
        <div className="interactive-page-register container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <div className="interactive-page-login-header">
                        <EventHeader />
                    </div>
                    <div className="interactive-page-login-countdown">
                        <Countdown />
                    </div>
                    {/* <div className="live-page-reg-warning">
                        <div className={showFade ? "fadein" : "fadeout"}>
                            {showWarningIE ?
                                <WarningIE
                                    clicked={onClickShowWarning}
                                />
                                :
                                null
                            }
                        </div>
                        <div className={!showFade ? "fadein" : "fadeout"}>
                            {!showWarningIE ?
                                <Button
                                    clicked={onClickShowWarning}
                                    withClass={["center", "secondary"]}
                                    classes="live-page-reg-warning__button"
                                    icon="faInfoCircle"
                                    iconPosition="left"
                                    type="button" > Browser Compatibility Info
                                </Button>
                                :
                                null
                            }
                        </div>
                    </div> */}
                    {/* back button */}
                    {
                        type === 'edit' &&
                        <Button type="submit"
                            withClass={["secondary", "center"]}
                            classes="interactive-page-reg-success-back u-font-size-12 font-weight-bold"
                            clicked={() => history.push('/')}
                        >&lt; BACK</Button>
                    }
                    <main className="interactive-page-form-wrapper">
                        <Formik
                            enableReinitialize
                            initialValues={initValues}
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                console.log(values);
                                handleSubmit(values);
                            }}
                        >
                            {({ errors, touched, values, isValid, setFieldValue }) => (
                                <Form className="interactive-page-form">
                                    {/* tabs */}
                                    <div className="interactive-page-form-tabs row">
                                        <div className={"interactive-page-form-tabs-register " + (type === 'new' ? "col-6" : "col-12") + " active"}>
                                            {
                                                type === 'new' ?
                                                    <Link to="/register" className="interactive-page-form-tabs__link text-decoration-none">
                                                        <h3 className="interactive-page-form-tabs__title mb-0 u-font-size-16 active">
                                                            REGISTER
                                                        </h3>
                                                        <hr className="interactive-page-form-tabs__selector" />
                                                    </Link> :
                                                    <h3 className="interactive-page-form-tabs__title mb-0 u-font-size-16 active">
                                                        UPDATE PROFILE
                                                    </h3>
                                            }
                                        </div>
                                        {
                                            type === 'new' &&
                                            <div className="interactive-page-form-tabs-update col-6">
                                                <Link to="/login" className="interactive-page-form-tabs__link text-decoration-none">
                                                    <h3 className="interactive-page-form-tabs__title mb-0 u-font-size-12">
                                                        LOGIN
                                                    </h3>
                                                </Link>
                                            </div>
                                        }
                                    </div>

                                    {/* content */}
                                    <div className="interactive-page-form-content">

                                        {/* first name & last name */}
                                        <div className="interactive-page-form__group form-row">
                                            <div className="col-md-6">
                                                <label className="interactive-page-form__label" htmlFor="firstName">First name</label>
                                                <Field
                                                    name="firstName"
                                                    type="text"
                                                    className="form-control interactive-page-form__field"
                                                />
                                                <div className={`invalid-feedback u-font-size-10 ${errors.firstName && touched.firstName ? "d-block" : ""}`}>
                                                    {errors.firstName}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-md-0 mt-3">
                                                <label className="interactive-page-form__label" htmlFor="lastName">Last name</label>
                                                <Field
                                                    name="lastName"
                                                    type="text"
                                                    className="form-control interactive-page-form__field"
                                                />
                                                <div className={`invalid-feedback u-font-size-10 ${errors.lastName && touched.lastName ? "d-block" : ""}`}>
                                                    {errors.lastName}
                                                </div>
                                            </div>
                                        </div>

                                        {/* email */}
                                        <div className="interactive-page-form__group">
                                            <label className="interactive-page-form__label" htmlFor="email">
                                                Email address {type === 'edit' ? '(cannot be changed)' : '(lowercase only)'}
                                            </label>
                                            <div className="input-group">
                                                <Field
                                                    name="email"
                                                    type="text"
                                                    className="form-control interactive-page-form__field"
                                                    disabled={type === 'edit'}
                                                    onChange={(e: any) => { setFieldValue('email', e.target.value.toLowerCase()) }}
                                                    validate={formValidateEmail}
                                                />
                                                {type === 'new' && <div className="input-group-addon u-font-size-9">@zimmerbiomet.com</div>}
                                            </div>
                                            <div className={`invalid-feedback u-font-size-10 ${errors.email && touched.email ? "d-block" : ""}`}>
                                                {errors.email}
                                            </div>
                                        </div>

                                        {/* country */}
                                        <div className="interactive-page-form__group">
                                            <label className="interactive-page-form__label" htmlFor="country">Country</label>
                                            <Field
                                                component="select"
                                                name="country"
                                                type="text"
                                                className="form-control interactive-page-form__field"
                                            >
                                                <option value="">--- Please select a country ---</option>
                                                {
                                                    countries.map(country => {
                                                        return <option key={country.value} value={country.value}>{country.text}</option>
                                                    })
                                                }
                                            </Field>
                                            <div className={`invalid-feedback u-font-size-10 ${errors.country && touched.country ? "d-block" : ""}`}>
                                                {errors.country}
                                            </div>
                                        </div>

                                        {/* password */}
                                        {
                                            type === 'new' &&
                                            <div className="interactive-page-form__group">
                                                <label className="interactive-page-form__label" htmlFor="password">Password</label>
                                                <Field
                                                    name="password"
                                                    type="password"
                                                    placeholder="Min. 8 characters"
                                                    className="form-control interactive-page-form__field"
                                                    validate={() => formValidatePassword(values.password)}
                                                />
                                                <div className={`invalid-feedback u-font-size-10 ${errors.password && touched.password ? "d-block" : ""}`}>
                                                    {errors.password}
                                                </div>
                                            </div>
                                        }

                                        {/* terms of service */}
                                        {
                                            type === 'new' &&
                                            <div className="interactive-page-form__group">
                                                <div className="checkbox">
                                                    <label className="interactive-page-form__label--sm">
                                                        <Field name="terms" type="checkbox" checked={values.terms} className="mr-2 mt-1" />
                                                        I have read and understood the <a href="/assets/docs/zb-privacy-policy-zbemeapulse.pdf" target="_blank">Privacy Policy</a> and agree with this document
                                                    </label>
                                                </div>
                                                <div className={`invalid-feedback u-font-size-10 ${errors.terms && touched.terms ? "d-block" : ""}`}>
                                                    {errors.terms}
                                                </div>
                                            </div>
                                        }

                                        {/* google captcha */}
                                        {
                                            type === 'new' &&
                                            <div className="interactive-page-form__group">
                                                <ReCAPTCHA
                                                    id="captcha"
                                                    sitekey={process.env.NODE_ENV === 'test' ?
                                                        "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" :
                                                        "6Lc2PU8aAAAAAOcSItVY0GaCoLi7bS0kUzmz3d6m"}
                                                    onChange={(value: string) => {
                                                        setFieldValue('captcha', value || '')
                                                    }}
                                                />
                                                <div className={`invalid-feedback u-font-size-10 ${errors.captcha && touched.captcha ? "d-block" : ""}`}>
                                                    {errors.captcha}
                                                </div>
                                            </div>
                                        }

                                        {/* submit button */}
                                        <div className="interactive-page-form__buttons">
                                            <Button type="submit"
                                                withClass={["primary", "small"]}
                                                icon="faChevronRight"
                                                iconPosition="right"
                                                classes="interactive-page-form__submit u-font-size-12 font-weight-bold"
                                                clicked={() => setValidation(true)}
                                                disabled={submittingForm}
                                            >{type === 'new' ? 'REGISTER' : 'EDIT PROFILE'}</Button>
                                        </div>

                                        {statusError === 200 && <div className="valid-feedback u-font-size-10 d-block mt-2">
                                            {type === 'new' ? "Your data has been submitted!" : "Data updated successfully!"}
                                        </div>}
                                        {showValidation && !isValid && <div className="invalid-feedback u-font-size-10 d-block">
                                            Please fill all mandatory fields
                                        </div>}
                                        <div className={`invalid-feedback ${isValid && error.length > 0 ? "d-block" : ""}`}>
                                            {error}
                                        </div>
                                        {
                                            error && error.indexOf('Email address already exists') >= 0 &&
                                            <div className="invalid-feedback d-block">
                                                Please Login <Link to="/login">HERE</Link>
                                            </div>
                                        }
                                        {
                                            type === 'new' &&
                                            <div className="interactive-page-form__group mt-4">
                                                <p>
                                                    Already have an account? <Link to="/login">Sign in</Link>
                                                </p>
                                            </div>
                                        }

                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </main>

                </div>
            </div>
        </div>
    )

}

export default RegisterPage;