import React from 'react';

const LivePageEventTitle = () => {

    return (
        <header className="live-page-event-title">
            <h1 className="live-page-event-title__title">
                {/*<img src='/assets/images/logo-summer-edition.png' alt="" />*/}
                <div className="live-page-event-title-group">
                    <h2 className="live-page-event-title__title--1 u-font-size-35"><b>EMEA</b> 2024</h2>
                    <p className="u-font-size-30">Town Hall</p>
                </div>
            </h1>
            <h3 className="live-page-event-title__date">
                {/* <img src='./assets/images/logo-summer-edition.png' alt="" /> */}
                <p className="live-page-event-title__date-day u-font-size-22">23<sup>rd</sup> SEPTEMBER 2024</p>
                <p className="live-page-event-title__date-time u-font-size-15">1:00pm - 2:00pm CEST</p>
            </h3>
        </header>
    )

}

export default LivePageEventTitle;