import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import axios from '../../config/axios';
import history from '../../config/history';
import { validateEmail, validatePassword, hideLoader, saveLog } from '../../config/functions'
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import MemberLog from '../../Models/Log/MemberLog';

import Button from '../../Components/_UI/Button/Button';
import EventHeader from '../../Components/EventHeader/EventHeader';
import Countdown from '../../Components/Countdown/Countdown';
import WarningIE from '../../Components/WarningIE/Warning';

var CryptoJS = require("crypto-js");

interface IProps {
    setToken: (token: string | null) => void,
    setProfile: (data: MembersTypeRes.IMember) => void,
    setGroup: (data: MembersTypeRes.IGroupAssociation) => void,
    isRegistrationActive: boolean,
}

const LoginPage = ({ setToken, setProfile, setGroup, isRegistrationActive }: IProps) => {

    const [statusError, setStatusError] = useState<number>(0);
    const [error, setError] = useState<string>('');
    // When submitting form avoid executing API call twice
    const [submittingForm, setSubmittingForm] = useState<boolean>(false);

    useEffect(() => {
        // if already logged redirect to home page
        if (window.storageGetItemValue('Auth-token') !== null) {
            // if(isRegistrationActive){
            //     history.push('/update-my-info');
            // } else {
            history.push('/');
            // }
        } else {
            hideLoader();
        }

        return () => {

        };

    }, [])

    // https://formik.org/docs/guides/validation
    // https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript

    const handleSubmit = (values: { password: string, email: string }) => {
        setStatusError(0);
        setError('');
        setSubmittingForm(true);
        const data = { userName: values.email, password: values.password, rememberMe: false }
        axios.post('Authentication/login', data).then(res => {
            const response = JSON.parse(JSON.stringify(res.data));
            if (response.status === 'success') {
                setStatusError(200);
                window.storageSetItem("Auth-token", response.data.token);
                if (response.data.deviceToken)
                    window.storageSetItem("Auth-device-token", response.data.deviceToken)
                window.storageSetItem("Auth-email", CryptoJS.AES.encrypt(values.email, '=$k@fU4P**vJzu!Cvf~ZSp7(;.F}tR\`_ce*(A3^:/dvdVE/[r').toString());
                window.storageSetItem("Auth-password", CryptoJS.AES.encrypt(values.password, '=$k@fU4P**vJzu!Cvf~ZSp7(;.F}tR\`_ce*(A3^:/dvdVE/[r').toString());
                //retrieve profile info:
                axios.get('Members/GetMyProfile?id=' + new Date().getTime()).then(res => {
                    const profileResponse = res.data;
                    if (profileResponse.status === "success") {
                        const myProfileData: MembersTypeRes.IMember = profileResponse.data;
                        window.storageSetItem("Auth-memberID", myProfileData.memberID);
                        window.storageSetItem("Auth-memberTypeID", myProfileData.memberTypeID);
                        window.storageSetItem("Auth-name", myProfileData.name);
                        window.storageSetItem("Auth-lastName", myProfileData.lastName);
                        window.storageSetItem("Auth-roles", myProfileData.memberRoles);
                        // if first login in case user was pre-registered or imported
                        window.storageSetItem("isFirstAccess", myProfileData.isFirstAccess ? 'S' : 'N');
                        var newLog: MemberLog = new MemberLog("/Login", "LoggedIn", "EMEA Town Hall - 2024-09-23", "Login", "", navigator.userAgent);
                        saveLog(newLog);
                        setToken(response.data.token);
                        setProfile(profileResponse.data);
                        setSubmittingForm(false);
                        history.push('/');
                    }
                }).catch(e => console.log(e))
            } else {
                if (response.message) {
                    setError(response.message);
                } else {
                    setStatusError(404);
                }
                setSubmittingForm(false);
            }

        }).catch(e => {
            const response = e.response;
            if (response && response.data && response.data.status === "error") {
                setError(response.data.message)
            } else {
                setError("An error has occurred")
            }
            setSubmittingForm(false);
        });
    }

    const formValidateEmail = (email: string) => {
        let error;
        if (!validateEmail(email))
            error = 'Invalid email';
        return error;
    }

    const formValidatePassword = (psw: string) => {
        let error;
        if (!validatePassword(psw)) {
            error = 'Invalid password';
        }
        return error;
    }

    const [showWarningIE, setShowWarningIE] = useState(true);
    const [showFade, setShowFade] = useState(true);
    function onClickShowWarning() {
        setShowFade(!showWarningIE);
        setShowWarningIE(!showWarningIE);
    }

    return (
        <div className="interactive-page-login container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <div className="interactive-page-login-header">
                        <EventHeader />
                    </div>
                    <div className="interactive-page-login-countdown">
                        <Countdown />
                    </div>
                    {/* <div className="live-page-reg-warning">
                        <div className={showFade ? "fadein" : "fadeout"}>
                            {showWarningIE ?
                                <WarningIE
                                    clicked={onClickShowWarning}
                                />
                                :
                                null
                            }
                        </div>
                        <div className={!showFade ? "fadein" : "fadeout"}>
                            {!showWarningIE ?
                                <Button
                                    clicked={onClickShowWarning}
                                    withClass={["center", "secondary"]}
                                    classes="live-page-reg-warning__button"
                                    icon="faInfoCircle"
                                    iconPosition="left"
                                    type="button" > Browser Compatibility Info
                                </Button>
                                :
                                null
                            }
                        </div>
                    </div> */}
                    <main className="interactive-page-form-wrapper">
                        <Formik
                            initialValues={{
                                password: '',
                                email: '',
                            }}
                            onSubmit={values => {
                                handleSubmit(values);
                            }}
                        >
                            {({ errors, touched, values, setFieldValue }) => (
                                <Form className="interactive-page-form">
                                    {submittingForm && (
                                        <div className="interactive-page-form-spinner-wrapper">
                                            <div className="spinner-border color-blue" role="status">

                                            </div>
                                        </div>
                                    )}
                                    {/* tabs */}
                                    <div className={"row interactive-page-form-tabs" + (submittingForm ? ' interactive-page-form-tabs--disabled' : '')}>
                                        {
                                            isRegistrationActive &&
                                            <div className="interactive-page-form-tabs-register col-6">
                                                <Link to="/register" className="interactive-page-form-tabs__link text-decoration-none">
                                                    <h3 className="interactive-page-form-tabs__title mb-0 u-font-size-12">
                                                        REGISTER
                                                    </h3>
                                                </Link>
                                            </div>
                                        }
                                        <div className={"interactive-page-form-tabs-update " + (isRegistrationActive ? "col-6" : "col-12") + " active"}>
                                            <Link to="/login" className="interactive-page-form-tabs__link text-decoration-none">
                                                <h3 className="interactive-page-form-tabs__title mb-0 u-font-size-16 active">
                                                    LOGIN
                                                </h3>
                                                {isRegistrationActive && <hr className="interactive-page-form-tabs__selector" />}
                                            </Link>
                                        </div>
                                    </div>
                                    {/* content */}
                                    <div className={"interactive-page-form-content" + (submittingForm ? ' interactive-page-form-content--disabled' : '')}>
                                        {/* <h2 className="interactive-page-form-content-title u-font-size-11 text-center mb-4">
                                            Please note that this is the same as your <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a> account
                                        </h2> */}
                                        {/* email */}
                                        <div className="interactive-page-form__group" >
                                            <label className={"interactive-page-form__label" + (submittingForm ? ' interactive-page-form__label--disabled' : '')} htmlFor="email">Email address (lowercase only)</label>
                                            <Field
                                                name="email"
                                                type="email"
                                                className="form-control interactive-page-form__field"
                                                onChange={(e: any) => { setFieldValue('email', e.target.value.toLowerCase()) }}
                                                validate={() => formValidateEmail(values.email)}
                                            />
                                            <div className={`invalid-feedback u-font-size-10 ${errors.email && touched.email ? "d-inline-block" : ""}`}>
                                                {errors.email}
                                            </div>
                                        </div>
                                        {/* password */}
                                        <div className="interactive-page-form__group">
                                            <label className={"interactive-page-form__label" + (submittingForm ? ' interactive-page-form__label--disabled' : '')} htmlFor="password">Password</label>
                                            <Field
                                                name="password"
                                                type="password"
                                                className="form-control interactive-page-form__field"
                                                validate={() => formValidatePassword(values.password)}
                                            />
                                            <div className={`invalid-feedback u-font-size-10 ${errors.password && touched.password ? "d-inline-block" : ""}`}>
                                                {errors.password}
                                            </div>
                                        </div>
                                        {/* submit button */}
                                        <div className="interactive-page-form__buttons">
                                            <span></span>
                                            <Button type="submit"
                                                withClass={["primary", "small"]}
                                                icon="faChevronRight"
                                                iconPosition="right"
                                                classes="interactive-page-form__submit u-font-size-12"
                                                disabled={submittingForm}
                                            >LOGIN</Button>
                                        </div>
                                        {statusError === 200 && <div className="valid-feedback u-font-size-9 d-inline-block">Login successful!</div>}
                                        {statusError === 401 && <div className="invalid-feedback u-font-size-9 d-inline-block">Unauthorized</div>}
                                        {statusError === 403 && <div className="invalid-feedback u-font-size-9 d-inline-block">Forbidden</div>}
                                        {statusError === 404 && <div className="invalid-feedback u-font-size-9 d-inline-block">Network error. Please check your internet connection and try again.</div>}
                                        {![0, 200, 401, 403, 404].includes(statusError) &&
                                            <div className="invalid-feedback u-font-size-9 d-inline-block">An error has occurred. Please try again later.</div>}
                                        <div className={`invalid-feedback u-font-size-9 ${error.length > 0 ? "d-inline-block" : ""}`}>
                                            {error}
                                        </div>

                                        <div className="interactive-page-form__group text-center mt-4">
                                            <Link className="forgotPasswordLink" to="/forgot-password">I forgot my password</Link>
                                        </div>

                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </main>
                </div>
            </div>
        </div>
    )

}

export default LoginPage;