function arrayBufferToBase16(arrayBuffer) {
  return Array.from(new Uint8Array(arrayBuffer))
    .reduce((acc, byte) => acc + ('00' + byte.toString(16)).slice(-2), '');
}

async function HMAC(key, message){
  const g = str => new Uint8Array([...unescape(encodeURIComponent(str))].map(c => c.charCodeAt(0))),
  k = g(key),
  m = g(message),
  c = await crypto.subtle.importKey('raw', k, { name: 'HMAC', hash: 'SHA-256' },true, ['sign']),
  s = await crypto.subtle.sign('HMAC', c, m);
  
  // Convert the result to a base16 string
	return arrayBufferToBase16(s);
}

/* TEST KEY ONLY - DO NOT USE KEY IN BROWSER JAVASCRIPT */


//HMAC(key, input).then(e => document.getElementById('out').innerText = e)

export async function getZbTvSignature(key, input) {
  const res = await HMAC(key, input);
  return res;
}