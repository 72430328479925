import React, { useEffect, useContext } from 'react';
import LivePageSectionHeader from './LivePageSectionTitle';
import { ContextAppSettings } from '../../config/context';
import { translations } from '../../config/translations';


const LivePagePolls = () => {

    const { language } = useContext(ContextAppSettings);
    
    useEffect(() => {
        console.log("LivePagePolls - childComponentsLoaded")
        window.dispatchEvent(new CustomEvent('childComponentsLoaded'));
    }, [])

    return (
        <section className="live-page-poll-wrapper">
            <LivePageSectionHeader 
                title={ translations.polls.header_title[language] }
                imgSource="assets/icons/polls_icon.png"
                subtitle=""
            />
            <div className="live-page-poll">
                {/* <h2>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reprehenderit, recusandae suscipit voluptas nihil laboriosam nemo pariatur debitis architecto, rem sunt cum at molestias.</h2>
                <p>Please indicate your preference among the answers.</p> */}
                {/* https://playbook.polleverywhere.com/conventions/iframe-apis/ */}
                <div className="live-page-poll-questions">
                    <img className="img-fluid" src="/assets/images/pollev-waiting-template.jpg"></img>
                    {/* <iframe id="poll-frame" className="live-page-poll-questions__frame" src="https://pollev-embeds.com/zb2021?controls=none&hide=sidebar,header"></iframe> */}
                </div>
            </div>
        </section>
    )

}

export default LivePagePolls;



